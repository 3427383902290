import { IStrings } from "../../constants/languageStrings/IStrings";

export const formatString = (string: string, value: string | number) => string.replaceAll("$0$", value.toString());

export const remainingDaysString = (strings: IStrings, days: number) => {
    if (days < 0) return strings.requests.Expired;
    else if (days === 0) return formatString(strings.requests.RemainingDays, 0);
    else if (days < 2) return strings.requests.RemainingDay;
    else if (days < 5) return formatString(strings.requests.RemainingDaysUpTo4, days);
    else return formatString(strings.requests.RemainingDays, days);
};
