import React, { useEffect, useRef, useState } from "react";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import styles from "./ReportViewer.module.scss";
import { DialogActionsBar, Dialog } from "@progress/kendo-react-dialogs";
import { ReportItem, ReportItemParam } from "../../model/ReportItem";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { IntlProvider } from "@progress/kendo-react-intl";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import MonthPicker from "../../components/MonthPicker/MonthPicker";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { selectUserFullname } from "../../redux/selectors/selectUserFullname";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import template from "./template.html";
import templateChrome from "./templateChrome.html";
import { useStrings } from "../../hooks/useStrings";
import { SpinnerBox } from "../../components/Spinner/SpinnerBox";
import { fetchReportData, fetchReportsItems } from "../../redux/actions/reports";
import { selectIxsRef } from "../../redux/selectors/selectIxsRef";
import AccountDropdown from "../../components/AccountDropdown";
import SpecificationsDropdown from "../../components/SpecificationsDropdown";

const isMobileOrTablet = window.innerWidth <= 600;
const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isApple =
    /iPhone/.test(navigator.userAgent) || /iPad/.test(navigator.userAgent) || /Macintosh/.test(navigator.userAgent);

function ReportParamRow(props: { param: ReportItemParam; value: any; setValue?: (value: any) => void }) {
    const fullName = useAppSelector(selectUserFullname);

    const Control = () => {
        switch (props.param.control) {
            case "none":
                switch (props.param.type) {
                    case "Boolean":
                        return (
                            <Checkbox
                                className={styles.check}
                                checked={props.value}
                                onChange={e => props.setValue?.(e.value)}
                            />
                        );
                    default:
                        return <></>;
                }
            case "person":
            case "persons":
                return <Input readOnly value={fullName} />;
            case "date":
                return <DatePicker value={props.value} onChange={e => props.setValue?.(e.value)} />;
            case "datemonth":
                return <MonthPicker selectedMonth={props.value} onPick={e => props.setValue?.(e)} />;
            case "selectone":
                return (
                    <DropDownList
                        data={props.param.options.split(";")}
                        onChange={e => props.setValue?.(e.value)}
                        value={props.value}
                    />
                );
            case "accounts":
                return <AccountDropdown selectedAccountId={props.value} onSelect={e => props.setValue?.(e)} />;
            case "specifikace":
                return (
                    <SpecificationsDropdown
                        specification={JSON.parse(props.param.options)}
                        selectedStrav={props.value}
                        onSelect={e => props.setValue?.(e)}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div className={styles.paramRow}>
            <label>{props.param.label + ":"}</label>
            <Control />
        </div>
    );
}

export default function ReportViewer1() {
    const dispatch = useAppDispatch();
    const strings = useStrings();

    const ixsref = useAppSelector(selectIxsRef);
    const code = useAppSelector(s => s.localization.language.code);
    const fetching = useAppSelector(s => s.session.reports.isFetching);
    const data = useAppSelector(s => s.session.reports.data);
    const reports = useAppSelector(s => s.session.reportItems.data);
    const isFetchingReport = useAppSelector(s => s.session.reportItems.isFetching);
    const permissions = useAppSelector(s => s.session.user.token?.userInvisibleActions ?? []);

    const [selectedReport, setSelectedReport] = useState<ReportItem>();
    const [dialogVisible, setDialogVisible] = useState(false);

    const save = useRef(strings.common.Save);
    const report = useRef<ReportItem>();

    const [params, setParams] = useState<Record<string, any>>({});

    useEffect(() => {
        save.current = strings.common.Save;
    }, [strings]);

    useEffect(() => {
        report.current = selectedReport;
    }, [selectedReport]);

    useEffect(() => {
        dispatch(fetchReportsItems());
    }, [dispatch, code]);

    useEffect(() => {
        const fileName = report.current?.uid + "_" + new Date().getFullDateString();

        const downloadPDF = () => {
            const a = document.createElement("a");
            a.href = `data:application/pdf;base64,${data}`;
            a.download = fileName;
            a.click();
        };

        const base64ToBlob = (base64: any) => {
            const binStr = atob(base64);
            const len = binStr.length;
            const arr = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }
            return new Blob([arr], { type: "application/pdf" });
        };

        const downloadPDFApple = () => {
            const a = document.createElement("a");
            a.href = URL.createObjectURL(base64ToBlob(data));
            a.download = fileName;
            a.target = "_blank";
            a.click();
        };

        if (data && report.current) {
            if (isApple) {
                downloadPDFApple();
            } else if (isMobileOrTablet) {
                downloadPDF();
            } else {
                const newWindow = window.open("", "_blank");
                if (newWindow) {
                    const content = (isChrome ? templateChrome : template)
                        .replaceAll("$DATA$", data.toString())
                        .replaceAll("$FILENAME$", fileName)
                        .replaceAll("$SAVE$", save.current);
                    newWindow.document.write(content);
                    newWindow.document.close();
                    newWindow.window.focus();
                }
            }
        }
        setSelectedReport(undefined);
    }, [data]);

    const saveOrOpenPDF = () => {
        dispatch(
            fetchReportData({
                Value: selectedReport?.classname,
                Value2: Object.keys(params).map(m => ({ Item1: m, Item2: params[m] })),
            })
        );
    };

    const openParametersDialog = (className: string) => {
        const item = reports?.find(ipo => className.startsWith(ipo.classname));
        setSelectedReport(item);
        let params: Record<string, boolean | string | Date | undefined> = {};
        item?.param.forEach(p => {
            switch (p.control) {
                case "none":
                    switch (p.type) {
                        case "Boolean":
                            params[p.name] = false;
                            break;
                        default:
                            params[p.name] = undefined;
                            break;
                    }
                    break;
                case "person":
                case "persons":
                    params[p.name] = ixsref;
                    break;
                case "date":
                    params[p.name] = new Date().getStartOfDay();
                    break;
                case "datemonth":
                    params[p.name] = new Date().firstDayOfMonth();
                    break;
                case "selectone":
                    params[p.name] = p.options.split(";").length > 0 ? p.options.split(";")[0] : "";
                    break;
                default:
                    switch (p.name) {
                        case "pstypuserid":
                            params[p.name] = ixsref;
                            break;
                        case "accessIxsRef":
                            params[p.name] = ixsref;
                            break;
                        default:
                            params[p.name] = undefined;
                            break;
                    }
                    break;
            }
        });
        setParams(params);
        setDialogVisible(true);
    };

    const handleSaving = () => {
        saveOrOpenPDF();
        setDialogVisible(false);
    };

    return (
        <PageLayout header={<NavBar canNavigateRoot label={strings.dashboard.Reports} menu={{ items: [] }} />}>
            <IntlProvider locale={code}>
                <div>
                    {!(isFetchingReport || fetching) && reports && (
                        <List>
                            {reports.map(
                                (row, key) =>
                                    !permissions.includes(row.permission ?? "") && (
                                        <ListItem
                                            key={key}
                                            divider
                                            dense
                                            button
                                            onClick={() => openParametersDialog(row.classname)}
                                        >
                                            <ListItemText primary={row.caption} />
                                        </ListItem>
                                    )
                            )}
                        </List>
                    )}
                    {dialogVisible && (
                        <Dialog
                            className={styles.dialogStyle}
                            title={strings.reports.Params}
                            closeIcon={false}
                            onClose={() => setDialogVisible(false)}
                            width="90%"
                        >
                            {selectedReport?.param
                                .filter(f => f.visible)
                                .map(p => (
                                    <ReportParamRow
                                        param={p}
                                        key={p.name}
                                        value={params[p.name]}
                                        setValue={e => setParams({ ...params, [p.name]: e })}
                                    />
                                ))}
                            <DialogActionsBar>
                                <Button
                                    className="k-button"
                                    onClick={() => setDialogVisible(false)}
                                    style={{ color: "black" }}
                                >
                                    {strings.common.Cancel}
                                </Button>
                                <Button
                                    autoFocus
                                    className="k-button"
                                    onClick={handleSaving}
                                    style={{ color: "green" }}
                                >
                                    {isMobileOrTablet ? strings.common.Save : strings.reports.LoadReport}
                                </Button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                    {(isFetchingReport || fetching) && <SpinnerBox stretchToWindow />}
                </div>
            </IntlProvider>
        </PageLayout>
    );
}
