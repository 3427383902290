import React from "react";
import { Badge } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Dashboard.module.scss";
import classNames from "classnames";
import SettingsDialog from "./SettingsDialog";
import ChangePersonDialog from "./ChangePersonDialog";
import { PageLayout } from "../../components/PageLayout/PageLayout";
import { NavBar } from "../../components/NavBar/NavBar";
import { RouteChildrenProps } from "react-router-dom";
import InfoPanel from "./InfoPanel";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { IStrings } from "../../constants/languageStrings/IStrings";
import { fetchDashboardData } from "../../redux/actions/dashboard";
import { IBaseMenuItem } from "../../components/NavBar/IBaseMenuItem";
import ReactDOM from "react-dom";
import PermissionGuard from "../../components/PermissionGuard";
import { permissions } from "../../constants/permissions";
import { useAppSelector } from "../../hooks/useAppSelector";
import { IAbortable } from "../../model/IAbortable";
import { AppDispatch } from "../..";
import { LogoutButton } from "../../components/LogoutButton";

enum EDialog {
    None,
    Settings,
    ChangePerson,
}

interface IDashboardState {
    visibleDialog: EDialog;
}

interface IDispatchProps {
    onFetchData: () => IAbortable;
}

interface IStateProps {
    strings: IStrings;
    checkAllowed: boolean;
    changeAllowed: boolean;
    visibleButtons: {
        myRequests: boolean;
        requestsForApproval: boolean;
        plnunavaForApproval: boolean;
    };
    isFetching: boolean;
}

type Props = IStateProps & IDispatchProps & RouteChildrenProps;

class Dashboard extends React.Component<Props, IDashboardState> {
    private abortable: IAbortable | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            visibleDialog: EDialog.None,
        };
    }

    private handleMenuItemClick = (item: IBaseMenuItem) => {
        switch (item.id) {
            case 1:
                this.setState({ visibleDialog: EDialog.Settings });
                break;
            case 2:
                this.setState({ visibleDialog: EDialog.ChangePerson });
                break;
            case 3:
                this.props.history.push("/personal-card");
                break;
            case 4:
                this.props.history.push("/change-password");
                break;
            default:
                item.id && console.warn("Unhandled menu item: ", item);
        }
    };

    private handleSettingsDialogClose = () =>
        this.setState({
            visibleDialog: EDialog.None,
        });

    private handleChangePersonDialogClose = () =>
        this.setState({
            visibleDialog: EDialog.None,
        });

    private createMenuItems = () => {
        const strings = this.props.strings;
        return [
            { id: 1, content: strings.dashboard.MyAccount, icon: "none" },
            // { id: 2, content: strings.dashboard.ChangePerson, icon: "none" },
            { id: 3, content: strings.dashboard.PersonalCard, icon: "user" },
            { id: 4, content: strings.passwordChange.title, icon: "none" },
        ] as IBaseMenuItem[];
    };

    componentDidMount() {
        this.abortable = this.props.onFetchData();
    }

    componentWillUnmount() {
        this.abortable?.abort();
    }

    public render() {
        return (
            <PageLayout
                header={
                    <NavBar
                        label="Dashboard"
                        menu={{
                            items: this.createMenuItems(),
                            onItemClick: this.handleMenuItemClick,
                        }}
                    >
                        <LogoutButton />
                    </NavBar>
                }
            >
                <div className={styles.contentContainer}>
                    <div className={styles.grid}>
                        <div className={styles.col1}>
                            <InfoPanel />
                        </div>
                        <div className={styles.col2}>
                            <div className={styles.buttonsContainer}>
                                <PermissionGuard permission={permissions.plan.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        primary
                                        onClick={() => this.props.history.push("/plan/my")}
                                    >
                                        <span className={classNames("fa fa-calendar", styles.icon)}></span>
                                        {this.props.strings.dashboard.MyPlan.toLocaleUpperCase()}
                                        {(this.props.checkAllowed || this.props.changeAllowed) && <MyPlanBadge />}
                                    </Button>
                                </PermissionGuard>
                                <PermissionGuard permission={permissions.workSheet.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/my-worksheet")}
                                        primary
                                    >
                                        <span className={"anetFont-worksheet"}></span>
                                        {this.props.strings.dashboard.MyWorksheet.toLocaleUpperCase()}
                                        <PermissionGuard permission={permissions.workSheet.sign}>
                                            <MyWorksheetBadge />
                                        </PermissionGuard>
                                    </Button>
                                </PermissionGuard>

                                {this.props.visibleButtons.myRequests && (
                                    <PermissionGuard permission={permissions.myRequests.run}>
                                        <Button
                                            disabled={this.props.isFetching}
                                            onClick={() => this.props.history.push("/my-requests")}
                                            primary
                                        >
                                            <span className={classNames("k-icon k-i-clock", styles.icon)}></span>
                                            <span>{this.props.strings.dashboard.MyRequests.toLocaleUpperCase()}</span>
                                            <MyRequestsBadge />
                                        </Button>
                                    </PermissionGuard>
                                )}

                                <PermissionGuard permission={permissions.unavailabilities.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/my-unavailability")}
                                        primary
                                    >
                                        <span className={classNames("k-icon k-i-cancel", styles.icon)}></span>
                                        <span>
                                            {this.props.strings.dashboard.MyUnavailabilities.toLocaleUpperCase()}
                                        </span>
                                        <MyRequestsPlnunavaBadge />
                                    </Button>
                                </PermissionGuard>

                                <PermissionGuard permission={permissions.overtime.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/overtime")}
                                        primary
                                    >
                                        <span className="anetFont-overtime"></span>
                                        <span>{this.props.strings.dashboard.Overtime}</span>
                                        <RequestsForOvertimeBadge />
                                    </Button>
                                </PermissionGuard>

                                {/* <Button>
									<span>{this.props.strings.dashboard.FreeShifts.toLocaleUpperCase()}</span>
									<Badge badgeContent={0} showZero className={styles.badge} color="primary">
									</Badge>
								</Button> */}
                                <PermissionGuard permission={permissions.departmentPlan.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/plan/department")}
                                    >
                                        <span className={"anetFont-plan"}></span>
                                        {this.props.strings.dashboard.DepartmentPlan.toLocaleUpperCase()}
                                    </Button>
                                </PermissionGuard>

                                {this.props.visibleButtons.requestsForApproval && (
                                    <PermissionGuard permission={permissions.requestsForApproval.run}>
                                        <Button
                                            disabled={this.props.isFetching}
                                            onClick={() => this.props.history.push("/requests-approve")}
                                        >
                                            <span className={"anetFont-workflow"}></span>
                                            <span>
                                                {this.props.strings.dashboard.RequestsToApprove.toLocaleUpperCase()}
                                            </span>
                                            <RequestsForApprovalBadge />
                                        </Button>
                                    </PermissionGuard>
                                )}

                                {this.props.visibleButtons.plnunavaForApproval && (
                                    <PermissionGuard permission={permissions.unavailabilitiesForApproval.run}>
                                        <Button
                                            disabled={this.props.isFetching}
                                            onClick={() => this.props.history.push("/unavailabilities-approve")}
                                        >
                                            <span className={"anetFont-workflow"}></span>
                                            <span>
                                                {this.props.strings.dashboard.RequestsToApprovePlnunava.toLocaleUpperCase()}
                                            </span>
                                            <RequestsForApprovalPlnunavaBadge />
                                        </Button>
                                    </PermissionGuard>
                                )}

                                <PermissionGuard permission={permissions.planStates.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/plan/states")}
                                    >
                                        <span className={classNames("fa fa-calendar-check-o", styles.icon)}></span>
                                        <span>{this.props.strings.dashboard.PlansStates.toLocaleUpperCase()}</span>
                                    </Button>
                                </PermissionGuard>

                                <PermissionGuard permission={permissions.workSheetStates.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/worksheet/states")}
                                    >
                                        <span className={"anetFont-worksheet-states"}></span>
                                        <span>{this.props.strings.dashboard.WorkSheetStates.toLocaleUpperCase()}</span>
                                    </Button>
                                </PermissionGuard>

                                <PermissionGuard permission={permissions.presence.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/presence")}
                                    >
                                        <span className={"anetFont-presence"}></span>
                                        <span>{this.props.strings.dashboard.Presence.toLocaleUpperCase()}</span>
                                    </Button>
                                </PermissionGuard>

                                <PermissionGuard permission={permissions.reports.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/reports")}
                                    >
                                        <span className={"anetFont-reports"}></span>
                                        <span>{this.props.strings.dashboard.Reports.toLocaleUpperCase()}</span>
                                    </Button>
                                </PermissionGuard>

                                <PermissionGuard permission={permissions.terminal.run}>
                                    <Button
                                        disabled={this.props.isFetching}
                                        onClick={() => this.props.history.push("/online-terminal")}
                                        className={styles.terminals}
                                    >
                                        <span className={classNames("fa fa-calculator", styles.icon)}></span>
                                        <span>{this.props.strings.dashboard.OnlineTerminal.toLocaleUpperCase()}</span>
                                    </Button>
                                </PermissionGuard>
                            </div>
                        </div>
                    </div>
                    {ReactDOM.createPortal(
                        <React.Fragment>
                            {this.state.visibleDialog === EDialog.Settings && (
                                <SettingsDialog onClose={this.handleSettingsDialogClose} />
                            )}
                            {this.state.visibleDialog === EDialog.ChangePerson && (
                                <ChangePersonDialog onClose={this.handleChangePersonDialogClose} />
                            )}
                        </React.Fragment>,
                        document.body
                    )}
                </div>
            </PageLayout>
        );
    }
}

export default connect<IStateProps, IDispatchProps, {}, IRootState>(
    state =>
        ({
            strings: state.localization.strings,
            checkAllowed:
                !state.session.user.token?.userDisabledActions.some(p => p === permissions.plan.check) ?? false,
            changeAllowed:
                !state.session.user.token?.userDisabledActions.some(p => p === permissions.plan.approve) ?? false,
            visibleButtons: {
                myRequests: state.session.dashboard.data?.isApprovable ?? false,
                requestsForApproval: state.session.dashboard.data?.isApprover ?? false,
                plnunavaForApproval: state.session.dashboard.data?.isApproverPlnunava ?? false,
            },
            isFetching: state.session.user.isAuthenticating || state.session.dashboard.isFetching,
        } as IStateProps),
    (dispatch: AppDispatch) => ({
        onFetchData: () => dispatch(fetchDashboardData()),
    })
)(Dashboard);

function BaseBadge(props: { loading: boolean; count: number }) {
    return (
        <Badge
            color="secondary"
            className={styles.badge}
            overlap="rectangular"
            badgeContent={!props.loading ? props.count : <span className="k-icon k-i-loading" />}
        />
    );
}

function MyPlanBadge() {
    const isLoading = useAppSelector<boolean>(s => s.session.dashboard.isFetching);
    const countCheck = useAppSelector<number>(s => s.session.dashboard.data?.requestsCounts?.planCheck ?? 0);
    const countApprove = useAppSelector<number>(s => s.session.dashboard.data?.requestsCounts?.planApprove ?? 0);
    const isCheckAllowed =
        !useAppSelector(s => s.session.user.token?.userDisabledActions.some(p => p === permissions.plan.check)) ??
        false;
    const isChangeAllowed =
        !useAppSelector(s => s.session.user.token?.userDisabledActions.some(p => p === permissions.plan.approve)) ??
        false;
    const count =
        isCheckAllowed && isChangeAllowed
            ? countCheck + countApprove
            : isCheckAllowed
            ? countCheck
            : isChangeAllowed
            ? countApprove
            : 0;

    return <BaseBadge loading={isLoading} count={count} />;
}

function MyWorksheetBadge() {
    const isLoading = useAppSelector<boolean>(s => s.session.dashboard.isFetching);
    const count = useAppSelector<number>(s => s.session.dashboard.data?.wsRequests?.worksheetRequestCount ?? 0);

    return <BaseBadge loading={isLoading} count={count} />;
}

function MyRequestsBadge() {
    const isLoading = useAppSelector<boolean>(s => s.session.dashboard.isFetching);
    const count = useAppSelector<number>(s => s.session.dashboard.data?.requestsCounts?.my ?? 0);

    return <BaseBadge loading={isLoading} count={count} />;
}

function MyRequestsPlnunavaBadge() {
    const isLoading = useAppSelector<boolean>(s => s.session.dashboard.isFetching);
    const count = useAppSelector<number>(s => s.session.dashboard.data?.requestsCounts?.myPlnunava ?? 0);

    return <BaseBadge loading={isLoading} count={count} />;
}

function RequestsForApprovalBadge() {
    const isLoading = useAppSelector<boolean>(s => s.session.dashboard.isFetching);
    const count = useAppSelector<number>(s => s.session.dashboard.data?.requestsCounts?.forApproval ?? 0);

    return <BaseBadge loading={isLoading} count={count} />;
}

function RequestsForApprovalPlnunavaBadge() {
    const isLoading = useAppSelector<boolean>(s => s.session.dashboard.isFetching);
    const count = useAppSelector<number>(s => s.session.dashboard.data?.requestsCounts?.forApprovalPlnunava ?? 0);

    return <BaseBadge loading={isLoading} count={count} />;
}

function RequestsForOvertimeBadge() {
    const isLoading = useAppSelector<boolean>(s => s.session.dashboard.isFetching);
    const count = useAppSelector<number>(s => s.session.dashboard.data?.requestsCounts?.overtime ?? 0);

    return <BaseBadge loading={isLoading} count={count} />;
}
