import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import classes from "./NewRequestDialog.module.scss";
import { DatePicker, DatePickerChangeEvent, TimePicker, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Checkbox, CheckboxChangeEvent, Input } from "@progress/kendo-react-inputs";
import { connect } from "react-redux";
import { IRootState } from "../../redux/reducers/root";
import { ILocalizationState } from "../../redux/slices/localization";
import { insertAbsencesToMultipleDates } from "../../redux/actions/absenceInsertion";
import { IporefForWidget } from "../../model/IporefForWidget";
import { IntlProvider } from "@progress/kendo-react-intl";
import AccountDropdown from "../AccountDropdown";
import { AppDispatch } from "../..";
import { unwrapResult } from "@reduxjs/toolkit";
import CustomCalendar from "./CustomCalendar";
import CustomPopup from "./CustomPopup";
import { INewRequestParams } from "../../model/INewRequestParams";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";
import PersonsForApprovalDropdown from "../PersonsForApprovalDropdown/PersonsForApprovalDropdown";
import { selectIxsRef } from "../../redux/selectors/selectIxsRef";
import { RequestsAgend } from "../../model/wflReq";
import { Error } from "@progress/kendo-react-labels";
import { IAccount } from "../../redux/reducers/dropdowns/accounts";

const position = {
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
};
class NewRequestDialog extends React.Component<Props, IState> {
    constructor(props: Props) {
        super(props);
        let tomorrow: Date = new Date().addDays(1).getStartOfDay();
        this.state = {
            dayOfBegin: this.props.defaults?.begin.getStartOfDay() ?? tomorrow,
            timeOfBegin:
                this.props.defaults?.begin ??
                (props.isRequest ? new Date(0, 0, 0, 8, 0, 0, 0) : new Date(0, 0, 0, 0, 0, 0, 0)),
            dayOfEnd: this.props.defaults?.end.getStartOfDay() ?? tomorrow,
            accountId: undefined,
            isWrongDayOrder: false,
            timeOfEnd:
                this.props.defaults?.end ??
                (props.isRequest ? new Date(0, 0, 0, 16, 30, 0, 0) : new Date(0, 0, 0, 23, 59, 0, 0)),
            isAllDay: this.props.defaults?.isAllDay ?? true,
            person: undefined,
            comment: "",
            isCommentEmpty: false,
        };
    }

    private handleDayOfBeginChange = (e: DatePickerChangeEvent) => {
        const dayOfBegin = e.value!;
        // checking of second datepicker only when selecting date in picker
        if (e.nativeEvent instanceof MouseEvent) {
            this.setState(state => ({
                dayOfBegin: dayOfBegin,
                dayOfEnd: state.dayOfEnd < dayOfBegin ? dayOfBegin : state.dayOfEnd,
            }));
        } else {
            this.setState(state => ({ dayOfBegin: dayOfBegin }));
            this.checkCorrectDateOrder(dayOfBegin, this.state.dayOfEnd);
        }
    };

    private handleTimeOfBeginChange = (e: TimePickerChangeEvent) => {
        const timeOfBegin = e.value!;
        this.setState({ timeOfBegin: timeOfBegin });
    };

    private handleDayOfEndChange = (e: DatePickerChangeEvent) => {
        const dayOfEnd = e.value!;
        // checking of second datepicker only when selecting date in picker
        if (e.nativeEvent instanceof MouseEvent) {
            this.setState(state => ({
                dayOfEnd: dayOfEnd,
                dayOfBegin: state.dayOfBegin > dayOfEnd ? dayOfEnd : state.dayOfBegin,
            }));
        } else {
            this.setState(state => ({ dayOfEnd: dayOfEnd }));
            this.checkCorrectDateOrder(this.state.dayOfBegin, dayOfEnd);
        }
    };

    private checkCorrectDateOrder = (dayBegin: Date, dayEnd: Date) => {
        if (dayBegin > dayEnd) {
            this.setState({ isWrongDayOrder: true });
        } else this.setState({ isWrongDayOrder: false });
    };

    private handleTimeOfEndChange = (e: TimePickerChangeEvent) => {
        const timeOfEnd = e.value!;
        this.setState({ timeOfEnd: timeOfEnd });
    };

    private handleSaveButtonClick = () => {
        if (!this.state.accountId && this.props.isRequest) return;
        if (!this.props.isRequest && this.state.comment === "") {
            this.setState({ isCommentEmpty: true });
            return;
        }
        const account = this.props.accounts?.find(f => f.id === this.state.accountId);
        this.props
            .onSave({
                objectID: this.props.isRequest ? RequestsAgend.Requests : RequestsAgend.Unavalabilities,
                accountID: account?.id ?? 0,
                accountABS: account?.abs ?? "",
                userID: this.state.person?.IXS_REF ?? this.props.userId ?? "",
                isAllDay: this.state.isAllDay,
                dayOfBegin: this.state.dayOfBegin,
                dayOfEnd: this.state.dayOfEnd,
                timeOfBegin: this.state.timeOfBegin,
                timeOfEnd: this.state.timeOfEnd,
                comment: this.state.comment ?? "",
            })
            .then(
                () =>
                    this.props.onClose &&
                    this.props.onClose({
                        canceled: false,
                        saved: true,
                    })
            );
    };

    private handleIsAllDayChange = (event: CheckboxChangeEvent) => {
        const isAllDay = event.value;

        this.setState(state => ({
            isAllDay: isAllDay,
            dayOfEnd: isAllDay ? state.dayOfBegin : state.dayOfEnd,
        }));
    };

    private handlePersonSelect = (person: IporefForWidget): void => {
        this.setState({ person: person });
    };

    private handleAccountIdSelect = (accountId: number): void => {
        this.setState({ accountId: accountId });
    };

    public render() {
        const steps = { minute: 30 };
        const isOverMidnight =
            !this.state.isAllDay && this.state.timeOfBegin.getTimeOfDay() > this.state.timeOfEnd.getTimeOfDay();
        const dateTo = isOverMidnight ? this.state.dayOfBegin.addDays(1) : this.state.dayOfEnd;
        return (
            <IntlProvider locale={this.props.language.code}>
                <Dialog
                    title={this.props.strings.requests.NewRequest}
                    autoFocus
                    closeIcon={false}
                    onClose={() =>
                        !this.props.isSaving &&
                        this.props.onClose &&
                        this.props.onClose({
                            canceled: true,
                            saved: false,
                        })
                    }
                >
                    <NotificationGroup className={classes.z} style={position}>
                        <Slide direction={!this.state.isWrongDayOrder ? "up" : "down"}>
                            {this.state.isWrongDayOrder && (
                                <Notification type={{ style: "error", icon: true }}>
                                    <span>{this.props.strings.requests.IsWrongDayOrder}</span>
                                </Notification>
                            )}
                        </Slide>
                    </NotificationGroup>

                    {this.props.canSelectPerson && (
                        <div className={classes.dropdownList}>
                            <PersonsForApprovalDropdown
                                isRequest={this.props.isRequest}
                                disabled={this.props.isSaving}
                                onSelectIxsref={this.handlePersonSelect}
                            />
                        </div>
                    )}
                    {this.props.isRequest && (
                        <>
                            <div className={classes.dropdownList}>
                                <AccountDropdown
                                    disabled={this.props.isSaving}
                                    selectedAccountId={this.state.accountId}
                                    onlyWorkflowAccounts={this.props.onlyWorkflow}
                                    onSelect={this.handleAccountIdSelect}
                                />
                            </div>
                            <div className={classes.checkboxRow}>
                                <Checkbox
                                    disabled={this.props.isSaving}
                                    checked={this.state.isAllDay}
                                    label={this.props.strings.requests.Fullday}
                                    onChange={this.handleIsAllDayChange}
                                />
                            </div>
                            <div className={classes.pickingRow}>
                                <label>{this.props.strings.requests.FromDate + ": "}</label>
                                <DatePicker
                                    value={this.state.dayOfBegin}
                                    disabled={this.props.isSaving}
                                    onChange={this.handleDayOfBeginChange}
                                    popup={CustomPopup}
                                    calendar={CustomCalendar}
                                />
                                {!this.state.isAllDay && (
                                    <TimePicker
                                        value={this.state.timeOfBegin}
                                        steps={steps}
                                        disabled={this.props.isSaving}
                                        onChange={this.handleTimeOfBeginChange}
                                        popup={CustomPopup}
                                    />
                                )}
                            </div>
                            <div className={classes.pickingRow}>
                                <label>{this.props.strings.requests.ToDate + ": "}</label>
                                <DatePicker
                                    disabled={!this.state.isAllDay || this.props.isSaving}
                                    value={dateTo}
                                    onChange={this.handleDayOfEndChange}
                                    popup={CustomPopup}
                                    calendar={CustomCalendar}
                                />
                                {!this.state.isAllDay && (
                                    <TimePicker
                                        value={this.state.timeOfEnd}
                                        steps={steps}
                                        disabled={this.props.isSaving}
                                        onChange={this.handleTimeOfEndChange}
                                        popup={CustomPopup}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {!this.props.isRequest && (
                        <>
                            <div className={classes.pickingRow}>
                                <label>{this.props.strings.common.Day + ": "}</label>
                                <DatePicker
                                    value={this.state.dayOfBegin}
                                    disabled={this.props.isSaving}
                                    onChange={this.handleDayOfBeginChange}
                                    popup={CustomPopup}
                                    calendar={CustomCalendar}
                                />
                            </div>
                            <div className={classes.pickingRow}>
                                <label>{this.props.strings.requests.FromDate + ": "}</label>
                                {
                                    <TimePicker
                                        value={this.state.timeOfBegin}
                                        steps={steps}
                                        disabled={this.props.isSaving}
                                        onChange={this.handleTimeOfBeginChange}
                                        popup={CustomPopup}
                                    />
                                }
                            </div>
                            <div className={classes.pickingRow}>
                                <label>{this.props.strings.requests.ToDate + ": "}</label>
                                {
                                    <TimePicker
                                        value={this.state.timeOfEnd}
                                        steps={steps}
                                        disabled={this.props.isSaving}
                                        onChange={this.handleTimeOfEndChange}
                                        popup={CustomPopup}
                                    />
                                }
                            </div>
                            <div>
                                <Input
                                    label={this.props.strings.requests.Comment}
                                    valid={!this.state.isCommentEmpty}
                                    onChange={e => this.setState({ comment: e.target.value?.toString() ?? "" })}
                                />
                                {this.state.isCommentEmpty && (
                                    <Error>{this.props.strings.requests.ErrorRequiredNoteUnava}</Error>
                                )}
                            </div>
                        </>
                    )}
                    <DialogActionsBar>
                        <Button
                            disabled={this.props.isSaving}
                            onClick={e =>
                                this.props.onClose &&
                                this.props.onClose({
                                    canceled: true,
                                    saved: false,
                                })
                            }
                        >
                            {this.props.strings.common.Close}
                        </Button>

                        <Button
                            onClick={this.handleSaveButtonClick}
                            disabled={this.props.isSaving || this.state.isWrongDayOrder}
                            icon={this.props.isSaving ? "loading" : undefined}
                        >
                            {this.props.strings.common.Save}
                        </Button>
                    </DialogActionsBar>
                </Dialog>
            </IntlProvider>
        );
    }
}

interface IState {
    isAllDay: boolean;
    dayOfBegin: Date;
    timeOfBegin: Date;
    dayOfEnd: Date;
    timeOfEnd: Date;
    accountId?: number;
    isWrongDayOrder: boolean;
    person?: IporefForWidget;
    isCommentEmpty: boolean;
    comment: string;
}

interface IOwnProps {
    onClose?: (e: ICloseEvent) => void;
    isRequest: boolean;
    canSelectPerson: boolean;
    onlyWorkflow: boolean;
    parentAgend?: RequestsAgend;
    defaults?: {
        begin: Date;
        end: Date;
        isAllDay: boolean;
    };
}

interface IStateProps extends ILocalizationState {
    userId?: string;
    isSaving: boolean;
    accounts?: IAccount[];
}

interface IDispatchProps {
    onSave: (p: INewRequestParams) => Promise<void>;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

export interface ICloseEvent {
    canceled: boolean;
    saved: boolean;
}

export default connect<IStateProps, IDispatchProps, IOwnProps, IRootState>(
    state => ({
        ...state.localization,
        userId: selectIxsRef(state),
        isSaving: state.session.absenceInsertion.inserting,
        accounts: state.session.dropdowns.accounts.accounts,
    }),
    (dispatch: AppDispatch) => ({
        onSave: p => dispatch(insertAbsencesToMultipleDates(p)).then(unwrapResult).then(),
    })
)(NewRequestDialog);
