import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./SettingsDialog.module.scss";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import LanguageDropDown from "../../components/LanguageDropDown/LanguageDropDown";
import { updatePersonalPreferences } from "../../redux/actions/dashboard";
import { localizationSlice } from "../../redux/slices/localization";
import { selectUserFullname } from "../../redux/selectors/selectUserFullname";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useStrings } from "../../hooks/useStrings";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
    createSubstitution,
    editSubstitution,
    fetchSubstitutions,
    toggleSubstitution,
} from "../../redux/actions/substitutions";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { Clear, Edit, Undo } from "@material-ui/icons";
import { IntlProvider } from "@progress/kendo-react-intl";
import { usePermissions } from "../../hooks/usePermissions";
import { permissions } from "../../constants/permissions";
import { Substitution } from "../../redux/reducers/substitutions";

interface IOwnProps {
    onClose?: () => void;
}

export function SettingsDialog(props: IOwnProps) {
    const [initialLanguage] = useState(useAppSelector(s => s.localization.language));

    const substitutionsAllowed = !usePermissions().some(s => s === permissions.settings.Substitutions);
    const substitutionsEnabled = useAppSelector(s => s.app.substitionsEnabled);
    const normalUser = useAppSelector(s => !s.session.user.token?.sidSubst);

    const dispatch = useAppDispatch();

    function handleSaveClick() {
        dispatch(updatePersonalPreferences());

        props.onClose && props.onClose();
    }

    function handleCloseClick() {
        dispatch(localizationSlice.actions.set(initialLanguage));

        props.onClose && props.onClose();
    }

    const strings = useStrings();
    return (
        <Dialog title={strings.dashboard.MyAccount} closeIcon={false} width="90%">
            <FullName />
            <LanguageDropDown label={strings.common.Language} />
            <EmailInput />
            {substitutionsAllowed && substitutionsEnabled && normalUser && <Substitutions />}
            <DialogActionsBar>
                <Button onClick={handleCloseClick}>{strings.common.Close}</Button>
                <Button onClick={handleSaveClick}>{strings.common.Save}</Button>
            </DialogActionsBar>
        </Dialog>
    );
}

export default SettingsDialog;

function FullName() {
    const fullname = useAppSelector<string>(s => selectUserFullname(s));
    return <span className={styles.textPerson}>{fullname}</span>;
}

function EmailInput() {
    const strings = useStrings();
    const email = useAppSelector(s => s.session.dashboard.data?.emailForNotifications);

    return <Input type="email" disabled label={strings.dashboard.EmailForNotifications} defaultValue={email ?? " "} />;
}

function Substitutions() {
    const dispatch = useAppDispatch();
    const substitutions = useAppSelector(s => s.session.substitution.substitutions);
    const anyPeopleForSubst = useAppSelector(s => s.session.substitution.people.length > 0);
    const loading = useAppSelector(s => s.session.substitution.isFetching);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [subst, setSubst] = useState<Substitution>();
    const strings = useStrings();

    useEffect(() => {
        dispatch(fetchSubstitutions());
    }, [dispatch]);

    return (
        <>
            <div className={styles.paramRow}>
                <span>{strings.substitutions.Substitution}</span>
                <Button
                    icon={loading ? "loading" : ""}
                    disabled={loading || !anyPeopleForSubst}
                    look="flat"
                    onClick={() => {
                        setSubst(undefined);
                        setDialogVisible(true);
                    }}
                >
                    {!loading && strings.substitutions.Add}
                </Button>
            </div>
            <List>
                {substitutions.map(sub => (
                    <ListItem key={sub.id} dense style={{ textDecoration: sub.deleted ? "line-through" : "" }}>
                        <ListItemText
                            primary={sub.name}
                            secondary={`${new Date(sub.start).toLocaleDateString()} - ${new Date(
                                sub.end
                            ).toLocaleDateString()}`}
                        />
                        <ListItemSecondaryAction>
                            {!sub.deleted && (
                                <IconButton
                                    title={strings.substitutions.Edit}
                                    edge="end"
                                    onClick={() => {
                                        setSubst(sub);
                                        setDialogVisible(true);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            )}
                            <IconButton
                                title={sub.deleted ? strings.substitutions.Undo : strings.common.Delete}
                                edge="end"
                                onClick={() => {
                                    dispatch(toggleSubstitution(sub.id));
                                }}
                            >
                                {sub.deleted ? <Undo /> : <Clear />}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            {dialogVisible && <SubstitutionDialog subst={subst} onClose={() => setDialogVisible(false)} />}
        </>
    );
}

function SubstitutionDialog(props: { subst?: Substitution; onClose: () => void }) {
    const dispatch = useAppDispatch();
    const strings = useStrings();
    const people = useAppSelector(s => s.session.substitution.people);
    const actions = useAppSelector(s => s.session.substitution.actions);
    const lang = useAppSelector(s => s.localization.language.code);
    const newSubst = !props.subst;
    const min = new Date().getStartOfDay();
    const [filter, setFilter] = useState("");

    const [start, setStart] = useState(newSubst ? min : new Date(props.subst!.start));
    const [end, setEnd] = useState(newSubst ? min : new Date(props.subst!.end));
    const [person, setPerson] = useState(
        newSubst ? (people.length > 0 ? people[0] : undefined) : people.find(f => f.ixsref === props.subst!.ixsref)
    );

    const [uncheckedActions, setUncheckedActions] = useState(newSubst ? [] : props.subst!.actions);

    const mappedActions = actions
        .map(a => ({ id: a.id, desc: strings.actions[a.id as keyof typeof strings.actions] ?? a.id }))
        .sort((a, b) => a.desc.localeCompare(b.desc));

    const handleSave = () => {
        if (person) {
            dispatch(
                newSubst
                    ? createSubstitution({
                          ixsref: person?.ixsref,
                          start: start.toISOStringIgnoringTZ(),
                          end: end.toISOStringIgnoringTZ(),
                          actions: uncheckedActions,
                      })
                    : editSubstitution({
                          id: props.subst!.id,
                          ixsref: person?.ixsref,
                          start: start.toISOStringIgnoringTZ(),
                          end: end.toISOStringIgnoringTZ(),
                          actions: uncheckedActions,
                      })
            );
            props.onClose();
        }
    };

    const handleStartChange = (e: DatePickerChangeEvent) => {
        if (e.value) {
            setStart(e.value);
            if (e.value > end) {
                setEnd(e.value);
            }
        }
    };

    const normalize = (str: string) =>
        str
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .toLocaleUpperCase();

    return (
        <Dialog title={strings.substitutions.Substitution} width="90%" onClose={props.onClose}>
            <IntlProvider locale={lang}>
                <span style={{ display: "flex", flexDirection: "column" }}>
                    <div className={styles.paramRow}>
                        <label>{strings.substitutions.Person}</label>
                        <DropDownList
                            filterable
                            filter={filter}
                            onFilterChange={e => setFilter(e.filter.value)}
                            data={people.filter(f => normalize(f.name).includes(normalize(filter)))}
                            textField="name"
                            value={person}
                            onChange={e => setPerson(e.value)}
                        />
                    </div>
                    <div className={styles.paramRow}>
                        <label>{strings.substitutions.From}</label>
                        <DatePicker
                            min={
                                newSubst ? min : new Date(props.subst!.start) > min ? min : new Date(props.subst!.start)
                            }
                            value={start}
                            onChange={handleStartChange}
                        />
                    </div>
                    <div className={styles.paramRow}>
                        <label>{strings.substitutions.To}</label>
                        <DatePicker min={start} value={end} onChange={e => e.value && setEnd(e.value)} />
                    </div>
                    <List>
                        {mappedActions.map(a => (
                            <ListItem dense key={a.id}>
                                <Checkbox
                                    label={a.desc}
                                    value={!uncheckedActions.some(s => s === a.id)}
                                    onChange={e =>
                                        e.value
                                            ? setUncheckedActions(uncheckedActions.filter(f => f !== a.id))
                                            : setUncheckedActions([...uncheckedActions, a.id])
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </span>
            </IntlProvider>
            <DialogActionsBar>
                <Button onClick={props.onClose}>{strings.common.Close}</Button>
                <Button onClick={handleSave}>{strings.common.Confirm}</Button>
            </DialogActionsBar>
        </Dialog>
    );
}
