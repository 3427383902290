import { Reducer } from "redux";
import { IUserState, userReducer } from "./user";
import { IMyRequestsState, myRequestsReducer } from "./myRequests";
import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { IAbsenceInsertionState, absenceInsertionReducer } from "./absenceInsertion";
import { IDashboardState, dashboardReducer } from "./dashboard";
import {
    IPersonsForApprovalState,
    IRequestsForApprovalState,
    personsForApprovingReducer,
    personsForApprovingWithFilterReducer,
    requestsForApprovalReducer,
} from "./requestsForApproval";
import { wflChangeStateReducer, IWFLChangeState } from "./wflStateChange";
import { IPersonalCardState, personalCardReducer } from "./personalCard";
import {
    IWorksheetState,
    worksheetReducer,
    IWorksheetParams,
    worksheetParamsReducer,
    IWorksheetSigning,
    signingWorksheetReducer,
    attachmentWorksheetReducer,
    IWorksheetAttachments,
} from "./worksheet";
import { IReportsState, reportsReducer, IReportItemsState, reportItemsReducer } from "./reports";
import {
    unavalabilityFetchReducer,
    IUnavailabilityGet,
    unavalabilityDeleteReducer,
    IUnavailabilityCreateOrRemove,
    unavalabilityCreateReducer,
} from "./unavailability";
import { IPlanState, planReducer } from "./plan";
import { logout } from "../actions/user";
import { IDropdownsState, dropdownsReducer } from "./dropdowns/dropdowns";
import { IPassInsertState, ITerminalState, passInsertReducer, terminalReducer } from "./terminal";
import { ILastPassesState, lastPassReducer } from "./passesLoader";
import { centerPickerReducer, ICenterPickerState } from "./centerPicker";
import {
    passwordChangeReducer,
    IPasswordChangeState,
    IPasswordParamState,
    passwordParamReducer,
    passwordOldVisibleReducer,
    IPasswordOldVisibleState,
} from "./passwordChange";
import { IWorksheetStatesState, worksheetStateReducer } from "./worksheetState";
import {
    IAccountsTranslationsState,
    IPassesTranslationsState,
    IPresenceState,
    IPresenceTranslationsState,
    accountsTranslationsReducer,
    passesTranslationsReducer,
    presenceReducer,
    presenceTranslationsReducer,
} from "./presence";
import {
    IPlanStatesParametersState,
    IPlanStatesState,
    planStateParametersReducer,
    planStateReducer,
} from "./planState";
import { categoriesReducer, ICategoriesState } from "./dropdowns/categories";
import { IUserListState, userListReducer } from "./userList";
import { IOvertimeState, overtimeReducer } from "./overtime";
import { ISubstitutuinState, substitutionReducer } from "./substitutions";

export interface ISessionState {
    user: IUserState;
    myRequests?: IMyRequestsState;
    requestsForApproval: IRequestsForApprovalState;
    personsForApproval: IPersonsForApprovalState;
    personsForApprovalWithFilter: IPersonsForApprovalState;
    dropdowns: IDropdownsState;
    absenceInsertion: IAbsenceInsertionState;
    wflChangeState: IWFLChangeState;
    dashboard: IDashboardState;
    personalCard: IPersonalCardState;
    reports: IReportsState;
    reportItems: IReportItemsState;
    worksheet: IWorksheetState;
    worksheetParams: IWorksheetParams;
    worksheetSign: IWorksheetSigning;
    worksheetAttachment: IWorksheetAttachments;
    plan: IPlanState;
    unavailabilityFetch: IUnavailabilityGet;
    unavailabilityCreate: IUnavailabilityCreateOrRemove;
    unavailabilityDelete: IUnavailabilityCreateOrRemove;
    terminals: ITerminalState;
    insertPass: IPassInsertState;
    lastPasses: ILastPassesState;
    centerPicker: ICenterPickerState;
    worksheetStates: IWorksheetStatesState;
    planStates: IPlanStatesState;
    planStatesParams: IPlanStatesParametersState;
    presence: IPresenceState;
    presenceTranslations: IPresenceTranslationsState;
    passesTranslations: IPassesTranslationsState;
    accountsTranslations: IAccountsTranslationsState;
    categories: ICategoriesState;
    passwordChange: IPasswordChangeState;
    passwordParam: IPasswordParamState;
    passwordOldVisible: IPasswordOldVisibleState;
    userList: IUserListState;
    overtime: IOvertimeState;
    substitution: ISubstitutuinState;
}

export const sessionReducer: Reducer<ISessionState, PayloadAction<unknown, string, never, SerializedError>> = (
    state,
    action
) => {
    if (logout.match(action)) {
        state = undefined; // causes state cleanup & reinit
    }
    return {
        user: userReducer(state?.user, action),
        myRequests: myRequestsReducer(state?.myRequests, action),
        requestsForApproval: requestsForApprovalReducer(state?.requestsForApproval, action),
        personsForApproval: personsForApprovingReducer(state?.personsForApproval, action),
        personsForApprovalWithFilter: personsForApprovingWithFilterReducer(state?.personsForApprovalWithFilter, action),
        dropdowns: dropdownsReducer(state?.dropdowns, action),
        absenceInsertion: absenceInsertionReducer(state?.absenceInsertion, action),
        wflChangeState: wflChangeStateReducer(state?.wflChangeState, action),
        dashboard: dashboardReducer(state?.dashboard, action),
        personalCard: personalCardReducer(state?.personalCard, action),
        reports: reportsReducer(state?.reports, action),
        reportItems: reportItemsReducer(state?.reportItems, action),
        worksheet: worksheetReducer(state?.worksheet, action),
        worksheetParams: worksheetParamsReducer(state?.worksheetParams, action),
        worksheetSign: signingWorksheetReducer(state?.worksheetSign, action),
        worksheetAttachment: attachmentWorksheetReducer(state?.worksheetAttachment, action),
        plan: planReducer(state?.plan, action),
        unavailabilityFetch: unavalabilityFetchReducer(state?.unavailabilityFetch, action),
        unavailabilityCreate: unavalabilityCreateReducer(state?.unavailabilityCreate, action),
        unavailabilityDelete: unavalabilityDeleteReducer(state?.unavailabilityDelete, action),
        terminals: terminalReducer(state?.terminals, action),
        insertPass: passInsertReducer(state?.insertPass, action),
        lastPasses: lastPassReducer(state?.lastPasses, action),
        centerPicker: centerPickerReducer(state?.centerPicker, action),
        worksheetStates: worksheetStateReducer(state?.worksheetStates, action),
        planStates: planStateReducer(state?.planStates, action),
        planStatesParams: planStateParametersReducer(state?.planStatesParams, action),
        presence: presenceReducer(state?.presence, action),
        presenceTranslations: presenceTranslationsReducer(state?.presenceTranslations, action),
        passesTranslations: passesTranslationsReducer(state?.passesTranslations, action),
        accountsTranslations: accountsTranslationsReducer(state?.accountsTranslations, action),
        categories: categoriesReducer(state?.categories, action),
        passwordChange: passwordChangeReducer(state?.passwordChange, action),
        passwordParam: passwordParamReducer(state?.passwordParam, action),
        passwordOldVisible: passwordOldVisibleReducer(state?.passwordOldVisible, action),
        userList: userListReducer(state?.userList, action),
        overtime: overtimeReducer(state?.overtime, action),
        substitution: substitutionReducer(state?.substitution, action),
    };
};
